<template>
    <div id="password">
        <el-form :rules="rules" :model="formData" ref="form_ref" label-width="95px"  label-position="right" size="small">
            <el-form-item label-width="246px" label-position="left">
                <template #label>
                    Hi {{ userInfo.nickname || '&nbsp;' }}，欢迎为碳问账号设置新密码
                </template>
                
            </el-form-item>
            <el-form-item label="旧密码：" prop="origin_password">
                <el-input type="password" size="small" v-model="formData.origin_password" placeholder="请输入旧密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="new_password">
                <el-input type="password" size="small" v-model="formData.new_password" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirm_password">
                <el-input type="password" size="small" v-model="formData.confirm_password" placeholder="请输入确认密码"></el-input>
            </el-form-item>
            <el-form-item label=" ">
                <el-button class="submit" @click="handleSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default{
        name: 'Password',
        data () {
            return {
                formData: {
                    origin_password: '',
                    new_password: '',
                    confirm_password: '',
                },
                rules: {
                    origin_password: [
                        { required: true, message:'请输入旧密码', trigger: 'blur' },
                    ],
                    new_password: [
                        { required: true, message:'请输入新密码', trigger: 'blur' },
                        { pattern: /^[^\s]{5,}$/, message:'长度至少为5位', trigger: 'blur' },
                    ],
                    confirm_password: [
                        { required: true, message:'请输入确认密码', trigger: 'blur' },
                    ],
                }
            }
        },
        activated () {
            console.log('activated, Password');
        },
        computed: {
            userInfo(){
                return this.$store.state.userInfo
            }
        },
        methods: {
            async handleSubmit(){
                let validatePromise = new Promise(resolve => {
                    this.$refs.form_ref.validate(valid =>{
                        resolve(valid);
                    })
                });
                let valid = await validatePromise;
                if(!valid) return;
                if (this.formData.new_password !== this.formData.confirm_password){
                    return this.$message({
                        type: 'warning',
                        message: '两次密码输入不一致'
                    });
                }
                // /api/user/editloginpassword
                this.api.postFormAPISM(
                    {
                        ...this.formData,
                    },
                    '/user/editloginpassword'
                ).then(res => {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    });
                }).catch(error => {
                    console.log('error', error);
                })
            }
        }
    }
</script>
<style lang="less">
    #password{
        &>.el-form{
            width: 400px;
            margin: 18px 0 0;
            & .el-form-item{
                & .submit{
                    width: 52px;
                    height: 26px;
                    line-height: 26px;
                    padding: 0;
                    text-align: center;
                    border-radius: 3px;
                    color: #000;
                    border: 1px solid #dbdbdb;
                    background-color: #fff;
                    font-size: 13px;
                    &.profile{
                        margin: 0 0 0 70px;
                    }
                    &.userLogo{
                        margin: 40px 0 0 99px;
                    }
                    &:hover {
                        background-color: #eee;
                        // background-image: -webkit-gradient(linear,left top,left bottom,from(#ddd),to(#ccc));
                        // background-image: -webkit-linear-gradient(top,#eee,#ccc);
                        // background-image: -moz-linear-gradient(top,#eee,#ccc);
                        // background-image: -ms-linear-gradient(top,#eee,#ccc);
                        // background-image: -o-linear-gradient(top,#eee,#ccc);
                        background-image: linear-gradient(to top, #eee, #ccc);
                        border-bottom: 1px solid #999;
                    }
                }
            }
        }
    }
</style>